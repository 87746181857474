import { Navbar, Group, Code, ScrollArea, createStyles, } from '@mantine/core';
import {
  IconUsers,
  IconNews,
  IconWindow,
  IconWall,
  IconLogout
} from '@tabler/icons';
import { useState } from 'react';
import { useLocation } from "react-router-dom"
import { useRealmApp } from '../api/RealmApp';
import { LinksGroup } from './LinksGroup';
import { SwitchToggle } from './SwitchToggle';

const NavData = [
  {
    label: 'Харилцагч',
    icon: IconUsers,
    links: [
      { label: 'Жагсаалт', link: '/users' },
    ],
  },
  {
    label: 'Бүтээгдэхүүн',
    icon: IconWall,
    links: [
      { label: 'Жагсаалт', link: '/products' },
      { label: 'Нэмэх', link: '/products/new' },
    ],
  },
  {
    label: 'Салбар',
    icon: IconWindow,
    links: [
      { label: 'Жагсаалт', link: '/branches' },
      { label: 'Нэмэх', link: '/branches/new' },
    ],
  },
  {
    label: 'Захиалга',
    icon: IconNews,
    links: [
      { label: 'Жагсаалт', link: '/orders' },
    ],
  },
  {
    label: 'Чанарын бичиг',
    icon: IconNews,
    links: [
      { label: 'Жагсаалт', link: '/qualities' },
      { label: 'Нэмэх', link: '/qualities/new' },
    ],
  },
  {
    label: 'Баннер',
    icon: IconNews,
    links: [
      { label: 'Жагсаалт', link: '/banners' },
      { label: 'Нэмэх', link: '/banners/new' },
    ],
  },
];

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef('icon');

  return {
    navbar: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      paddingBottom: 0,
    },
    links: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md,
    },

    linksInner: {
      paddingTop: theme.spacing.xl,
      paddingBottom: theme.spacing.xl,
    },

    footer: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
    },
    linkIcon: {
      ref: icon,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },
    darkToggle: {
      display: 'flex',
      alignItems: 'center',
    }
  }
});

export function NavbarNested({opened, setOpened}: {opened: boolean, setOpened: (opened: boolean) => void}) {
  const { classes } = useStyles();
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const links = NavData.map((item) => <LinksGroup {...item} active={active} setActive={setActive} key={item.label} closeNav={setOpened} />);

  const { logOut } = useRealmApp();

  return (
    <Navbar hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, md: 300 }} p="md" className={classes.navbar} fixed>
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <Group position="apart">
          <a href="#" className={classes.link} onClick={async (event) => {
            event.preventDefault();
            // @ts-ignore
            await logOut();
          }}>
            <IconLogout className={classes.linkIcon} stroke={1.5} />
            <span>Logout</span>
          </a>
          <SwitchToggle/>
        </Group>
      </Navbar.Section>
    </Navbar>
  );
}