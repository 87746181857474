import { Table, ActionIcon, Group, Skeleton, Anchor } from '@mantine/core';
import { useUsers } from '../hooks/useUsers';


export default function Users() {
  const { loading, users } = useUsers();
  
  const rows = users.map((element)  => {
    return (
      <tr key={element._id.toString()}>
        <td>
          {element.name}
        </td>
        <td>
          {element.phone}
        </td>
        <td>
          {element.email}
        </td>
        <td>
          {element.car_no}
        </td>
      </tr>
    )
  });
  
  const rowsLoading = Array(10).fill(0).map((element, index)  => {
    return (
      <tr key={index}>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
      </tr>
    )
  });

  return (
    <Table>
      <thead>
          <tr>
            <th>Нэр</th>
            <th>Утас</th>
            <th>Имейл</th>
            <th>Машин</th>
          </tr>
      </thead>
      <tbody>
        {loading ? rowsLoading : rows}
      </tbody>
    </Table>
  );
  }