import { Table, ActionIcon, Group, Skeleton, Anchor } from '@mantine/core';
import { useProducts } from '../hooks/useProducts';
import * as dayjs from 'dayjs'
import { Link } from 'react-router-dom';
import { IconPencil, IconTrash } from '@tabler/icons';


export default function Products() {
  const { loading, products } = useProducts();
  
  const rows = products.map((element)  => {
    return (
      <tr key={element._id.toString()}>
        <td>
          {element.name}
        </td>
        <td>
          {element.price}
        </td>
        <td>
          {element.status}
        </td>
        <td>
          {dayjs(element.created_at).format('YYYY-MM-DD')}
        </td>
        <td>
          <Group spacing={0} position="right">
            <Link to={`/products/${element._id.toString()}`}>
              <ActionIcon>
                <IconPencil size={16} stroke={1.5} />
              </ActionIcon>
            </Link>
          </Group>
        </td>
      </tr>
    )
  });
  
  const rowsLoading = Array(10).fill(0).map((element, index)  => {
    return (
      <tr key={index}>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
      </tr>
    )
  });

  return (
    <Table>
      <thead>
          <tr>
            <th>Нэр</th>
            <th>Үнэ</th>
            <th>Статус</th>
            <th>Нэмсэн Огноо</th>
            <th></th>
          </tr>
      </thead>
      <tbody>
        {loading ? rowsLoading : rows}
      </tbody>
    </Table>
  );
  }