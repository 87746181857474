import { Table, ActionIcon, Group, Skeleton, Anchor } from '@mantine/core';
import { useOrders } from '../hooks/useOrders';
import * as dayjs from 'dayjs'


export default function Orders() {
  const { loading, orders } = useOrders();
  
  const rows = orders.map((element)  => {
    return (
      <tr key={element._id.toString()}>
        <td>
          {element.product}
        </td>
        <td>
          {element.quantitiy}
        </td>
        <td>
          {element.totalPrice}
        </td>
        <td>
        {dayjs(element.created_at).format('YYYY-MM-DD')}
        </td>
      </tr>
    )
  });
  
  const rowsLoading = Array(10).fill(0).map((element, index)  => {
    return (
      <tr key={index}>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
      </tr>
    )
  });

  return (
    <Table>
      <thead>
          <tr>
            <th>Нэр</th>
            <th>Тоо ширхэг</th>
            <th>Нийт үнэ</th>
            <th>Нэмсэн Огноо</th>
          </tr>
      </thead>
      <tbody>
        {loading ? rowsLoading : rows}
      </tbody>
    </Table>
  );
  }